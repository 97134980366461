<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-time">
        <div class="card-year">{{ data.checkYear}}</div>
        <div class="card-data">{{ data.checkMonth}}</div>
        <div class="card-hour">{{ data.checkTime}}</div>
        <div class="card-line"></div>
      </div>
      <slot>
        <div class="card-content">
          <div class="card-a" v-if="data.name">{{data.name}}</div>
          <div class="card-b" v-if="data.branchOrgTitle || data.orgTitle">{{ `${data.branchOrgTitle ? data.branchOrgTitle + '-' + data.orgTitle : data.orgTitle ? data.orgTitle : ''}`}}</div>
          <div class="card-c" v-if="data.checkAddr" >
            <i class="icon-joyo site">&#xe72b;</i>
            <i class="card-addr">{{ data.checkAddr}}</i>
          </div>
          <div class="card-d" v-if="data.fileDetails && data.fileDetails.length">
            <div class="card-d-img" v-for="(item, index) of data.fileDetails" :key="index"  @click="showImagePreview(data.fileDetails)">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    showImagePreview(imgs) {
      this.$createImagePreview({
        imgs: imgs
      }).show();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box{
  width:100%;
  // height:78px;
  background-color: $color-FFF;
  padding-top:10px ;
  .card-cont{
    display: flex;
    justify-content: flex-start;
    padding: 0 0 0 14px;
    text-align: left;
    .card-time{
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      .card-data{
        color:$color-333;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 9px;
      }
      .card-year{
        color:$color-999;
        font-size:$font-12;
        margin-bottom:9px;
      }
      .card-hour{
        color:$color-999;
        font-size:$font-12;
        margin-bottom:10px;
      }
      .card-line{
        flex: 1 ;
        width: 1px;
        background: $color-E6;
        margin: 0 auto;
        min-height: 20px;
      }
    }
    .card-content{
      flex: 1;
      margin: 0 0 24px 20px;
      .card-a{
        font-size:$font-15;
        color:$color-333;
        margin-bottom: 9px;
      }
      .card-b{
        font-size:$font-14;
        color:$color-999;
        margin-bottom: 14px;
      }
      .card-c{
        display: flex;
        justify-content:flex-start;
        font-size:$font-14;
        color:$color-font;
        margin:0 15px 14px 0;
        line-height: 16px;
        .site{
          margin-right: 5px;
        }
        .card-addr{
          line-height: 18px;
        }
      }
      .card-d{
        display: flex;
        justify-content: flex-start;
        .card-d-img{
          width: 50px;
          height: 50px;
          border-radius: 2px;
          img{
            width: 50px;
            height: 50px;
          }
        }
      }
      .card-company {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        padding-right: 15px;
        .card-aa {
          color: $color-333;
          font-size: $font-15;
          font-weight: 600;
          line-height: 1.5;
        }
        .card-ab {
          flex: 0 0 60px;
          text-align: right;
          font-size: $font-15;
          color: $color-321;
          font-weight: 600;
          line-height: 1.5;
        }
      }
      .card-sell {
        display: flex;
        justify-content: space-between;
        .card-sell-a {
          color: $color-999;
          font-size: $font-14;
          &.remind {
            color: $color-remind;
          }
        }
        .card-sell-a:last-child {
          padding-right: 18px;
        }
      }
      .card-follow{
        display: flex;
        justify-content: space-between;
        .card-follow-a{
          color:$color-999;
          font-size:$font-14;
        }
        .card-follow-b{
          color:$color-999;
          font-size:$font-14;
          .arrows{
            color:$color-CCC;
            font-size:$font-12;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
