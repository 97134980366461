<template>
  <div class="section">
    <cust-header
      class="header"
      :data="headerInfo"
    />
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="168"
      :need-btn="needBtn"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-border="false"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper"
      >
        <!--有权限 -->
        <div
          v-if="$route.params.type === '1'"
          class="outing"
        >
          <div
            v-for="(item, indexs) of trackList"
            :key="indexs"
            class="out-box"
            @click="toFollowDetail(item.id)"
          >
            <card-timeline :data="item.mainObj" />
          </div>
          <error-page v-if="!trackList.length" />
        </div>
        <!--没有权限 没有人员信息-->
        <div
          v-else
          class="outing"
        >
          <div
            v-for="(item, indexs) of trackList"
            :key="indexs"
            class="out-box"
            @click="toFollowDetail(item.id)"
          >
            <card-without :data="item.mainObj" />
          </div>
          <error-page v-if="!trackList.length" />
        </div>
      </ul>
      <ul
        slot="2"
        class="list-wrapper"
      >
        <cust-info ref="custInfo" />
      </ul>
      <ul
        slot="3"
        class="list-wrapper"
      >
        <card-contacts
          v-for="(item, indexs) of contactsList"
          :key="indexs"
          :data="item.mainObj"
        />
        <error-page v-if="!contactsList.length" />
      </ul>
      <ul
        slot="4"
        class="list-wrapper outing"
      >
        <div
          v-for="(item, indexs) of historyList"
          :key="indexs"
          class="out-box"
        >
          <card-address :data="item.mainObj" />
        </div>
        <error-page v-if="!historyList.length" />
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button
        v-if="index === 0 && btnAid.includes('addfollow')"
        class="btn"
        @click="toFollow"
      >
        添加跟进
      </cube-button>
      <cube-button
        v-if="index === 1 && btnAid.includes('edit')"
        class="btn"
        @click="toCustEdit"
      >
        编辑公司信息
      </cube-button>
      <cube-button
        v-if="index === 2 && btnAid.includes('addcont')"
        class="btn"
        @click="toContact"
      >
        添加联系人
      </cube-button>
    </div>
  </div>
</template>

<script >
import custHeader from '_c/cust-header';
import tabComposite from '_c/tab-composite';
import cardTimeline from '_c/tab-card/card-timeline';
import cardAddress from '_c/tab-card/card-address';
import cardContacts from '_c/tab-card/card-contacts';
import custInfo from '@/views/cust/components/cust-info';
import errorPage from '_c/errorPage';
import cardWithout from '_c/tab-card/card-without';
import { findValueByKey } from '@/lib/until';
import { custDetail } from '_api/cust';
import btnAuth from '@/mixins/btnAuth';
import { mapGetters } from 'vuex';

export default {
  name: 'CustDetail',
  components: {
    custHeader,
    tabComposite,
    cardTimeline,
    cardAddress,
    cardContacts,
    custInfo,
    cardWithout,
    errorPage
  },
  mixins: [btnAuth],
  data() {
    return {
      headerInfo: {},
      custInfoDetail: {}, // 客户信息
      trackList: [], // 跟进信息
      contactsList: [], // 联系人信息
      historyList: [], // 拜访签到
      tabList: [{
        label: '跟进信息',
        value: 0
      }, {
        label: '客户信息',
        value: 1
      }, {
        label: '联系人',
        value: 2
      }, {
        label: '拜访签到',
        value: 3
      }],
      index: 0,
      loadEnable: false
    };
  },
  computed: {
    ...mapGetters('cust', ['aidList']),
    ...mapGetters(['dictList']),
    needBtn() {
      if (this.index === 0 && this.btnAid.includes('addfollow')) {
        return true;
      } else if (this.index === 1 && this.btnAid.includes('edit')) {
        return true;
      } else if (this.index === 2 && this.btnAid.includes('addcont')) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getAuthBtn(this.$route.params.type);
  },
  mounted() {
    this.getDetail(this.$route.params.aid);
  },
  methods: {
    getDetail(aid) {
      custDetail({custId: this.$route.params.custId}, aid).then((res) => {
        if (res.flag) {
          // 头部信息
          let custInfoDetail = res.data.custInfoDetail;
          this.headerInfo = {
            title: custInfoDetail.custCn,
            status: findValueByKey(this.dictList.cooperationStatus, custInfoDetail.cooperationStatus),
            mainList: [
              `客户来源：${findValueByKey(this.dictList.clueSource, custInfoDetail.source)}`
            ],
            tagList: [
              `${custInfoDetail.custInd ? custInfoDetail.custInd : ''}`,
              `${findValueByKey(this.dictList.scale, custInfoDetail.scale)}`
            ].filter(Boolean)
          };
          // 跟进信息
          this.trackList = res.data.trackListDetails;
          this.trackList.forEach(item => {
            item.mainObj = {
              trackTime: item.trackDate ? item.trackDate.substring(5, item.trackDate.length) : '',
              trackYear: item.trackDate ? item.trackDate.substring(0, 4) : '',
              name: item.sellerUserTitle,
              trackType: findValueByKey(this.dictList.trackType, item.trackType),
              branchOrgTitle: item.branchOrgTitle,
              orgTitle: item.orgTitle,
              comboName: item.comboName,
              status: findValueByKey(this.dictList.oppoStatus, item.oppoStatus),
              trackRemark: item.trackRemark || ''
            };
          });
          // 客户信息
          this.custInfoDetail = res.data.custInfoDetail;
          if (this.$refs.custInfo) this.$refs.custInfo.getData(this.custInfoDetail);
          // 联系人信息
          this.contactsList = res.data.contactsListDetails;
          this.contactsList.forEach(item => {
            item.mainObj = {
              name: item.name,
              position: item.position,
              mobile: item.mobile
            };
          });
          // 拜访签到
          this.historyList = res.data.historyList;
          this.historyList.forEach(item => {
            let fileUrl = item.fileDetails.map(item=>item.fileUrl);
            item.mainObj = {
              checkYear: item.registerDate ? item.registerDate.substring(0, 4) : '',
              checkMonth: item.registerDate ? item.registerDate.substring(5, 10) : '',
              checkTime: item.registerDate ? item.registerDate.substring(11, item.registerDate.length) : '',
              name: item.registerName,
              branchOrgTitle: item.branchOrgTitle,
              orgTitle: item.registerOrgTitle,
              checkAddr: item.checkAddr,
              fileDetails: fileUrl || []
            };
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    toCustEdit() {
      this.$router.push(
        {
          name: 'cust-edit',
          params: {
            custId: this.custInfoDetail.id
          }
        }
      );
    },
    toFollowDetail(id) {
      this.$router.push(
        {
          name: 'follow-details',
          params: {
            followId: id
          }
        }
      );
    },
    toFollow() {
      this.$router.push(
        {
          name: 'AddeditFollow',
          query: {
            custId: this.custInfoDetail.id,
            custCn: this.custInfoDetail.custCn,
            from: 'fromCust'
          }
        }
      );
    },
    toContact() {
      this.$router.push(
        {
          name: 'addedit-contact',
          query: {
            custCn: this.custInfoDetail.custCn,
            custId: this.custInfoDetail.id
          }
        }
      );
    }
  }
};
</script>

<style  lang="scss"  scoped>
@import '@/assets/css/components/btn-bottom.scss';
.header{
  width:100%;
  height:168px;
}
.outing{
  .out-box:first-child{
    .card-box{
      padding-top:24px ;
    }
  }
  .out-box:last-child{
    .card-box{
      padding-bottom:24px ;
    }
  }
}
</style>
